import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Box,
  Spinner,
  FormHelperText,
} from "@chakra-ui/react";
import { fetchCouponDetails, updateCoupon } from "../api";

const EditCouponModal = ({
  isOpen,
  onClose,
  couponId,
  startDate,
  onCouponUpdate,
}) => {
  const [couponDetails, setCouponDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCouponDetails = async () => {
      setLoading(true);
      try {
        const data = await fetchCouponDetails(couponId, startDate);
        setCouponDetails(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      getCouponDetails();
    }
  }, [isOpen, couponId, startDate]);

  const handleSave = async () => {
    try {
      const {
        startDate,
        newCostForDataProcessed,
        newCostForDataStored,
        newCostForDataExported,
        couponId,
        ...updatedDetails
      } = couponDetails;
      const updatedCoupon = await updateCoupon(
        couponId,
        startDate,
        updatedDetails
      );
      console.log("Updated coupon:", updatedCoupon);
      setCouponDetails(updatedCoupon);
      onClose();
      onCouponUpdate();
    } catch (error) {
      console.error("Error updating coupon:", error);
      setError(error.message);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Coupon</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading && (
            <Box textAlign="center" p={4}>
              <Spinner />
            </Box>
          )}
          {error && <Box color="red.500">{error}</Box>}
          {couponDetails && !loading && (
            <>
              <FormControl mb={4}>
                <FormLabel>Coupon Name</FormLabel>
                <Input value={couponDetails.couponName} readOnly />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Coupon Code</FormLabel>
                <Input
                  value={couponDetails.couponCode}
                  onChange={(e) =>
                    setCouponDetails({
                      ...couponDetails,
                      couponCode: e.target.value.toUpperCase(),
                    })
                  }
                  maxLength={10}
                />
                <FormHelperText>{`${
                  couponDetails.couponCode?.length || 0
                }/10 characters`}</FormHelperText>
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Expiry Date</FormLabel>
                <Input
                  type="date"
                  value={couponDetails.expiryDate}
                  onChange={(e) =>
                    setCouponDetails({
                      ...couponDetails,
                      expiryDate: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Seller Name</FormLabel>
                <Input
                  value={couponDetails.sellerName}
                  onChange={(e) =>
                    setCouponDetails({
                      ...couponDetails,
                      sellerName: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel mb="0">Active</FormLabel>
                <Switch
                  isChecked={couponDetails.isActive}
                  onChange={(e) =>
                    setCouponDetails({
                      ...couponDetails,
                      isActive: e.target.checked,
                    })
                  }
                />
              </FormControl>
              <FormControl mb={4} mt={4}>
                <FormLabel>Discount Against Data Stored %</FormLabel>
                <Input
                  type="number"
                  value={couponDetails.discountAgainstDataStored}
                  onChange={(e) =>
                    setCouponDetails({
                      ...couponDetails,
                      discountAgainstDataStored: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Discount Against Data Exported %</FormLabel>
                <Input
                  type="number"
                  value={couponDetails.discountAgainstDataExported}
                  onChange={(e) =>
                    setCouponDetails({
                      ...couponDetails,
                      discountAgainstDataExported: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Discount Against Data Processed %</FormLabel>
                <Input
                  type="number"
                  value={couponDetails.discountAgainstDataProcessed}
                  onChange={(e) =>
                    setCouponDetails({
                      ...couponDetails,
                      discountAgainstDataProcessed: e.target.value,
                    })
                  }
                />
              </FormControl>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
          <Button ml={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditCouponModal;
