import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import NavMenu from './included/NavMenu';

const Login = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState(''); // For new password input
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [challenge, setChallenge] = useState(null); // Track if we are in a challenge
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    setError('');
    try {
      const user = await Auth.signIn(username, password); // Sign in the user

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setChallenge(user); // Save the user object for the challenge
        setError('A new password is required. Please provide a new password.');
        setPassword(''); // Clear old password
      } else {
        const jwtToken = user.signInUserSession.idToken.jwtToken;
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        localStorage.setItem("accessToken",accessToken);
        // Now you can use the access token as needed

        onLoginSuccess(jwtToken); // Pass the token to parent component
        navigate('/'); // Redirect to home or admin panel
      }
    } catch (err) {
      console.error('Login error:', err);

      if (err.code === 'UserNotFoundException') {
        setError('User does not exist.');
      } else if (err.code === 'NotAuthorizedException') {
        setError('Incorrect username or password.');
      } else {
        setError(`An unexpected error occurred: ${err.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleNewPasswordSubmit = async () => {
    if (!newPassword) {
      setError('Please enter a new password.');
      return;
    }

    try {
      const userAfterChallenge = await Auth.completeNewPassword(challenge, newPassword);
      const jwtToken = userAfterChallenge.signInUserSession.idToken.jwtToken; // Get the JWT token
      onLoginSuccess(jwtToken); // Pass the token to parent component
      navigate('/'); // Redirect to home or admin panel
    } catch (err) {
      console.error('Error completing new password challenge:', err);
      setError(`An error occurred while setting the new password: ${err.message}`);
    }
  };

  return (
    <>
      {/* NavMenu Component */}
      <NavMenu isAuthenticated={false} logoutUser={() => {}} /> {/* Set isAuthenticated to false for the login page */}
      
      {/* Login Form */}
      <Box maxW="400px" mx="auto" mt={10} p={5} borderWidth="1px" borderRadius="lg">
        <Heading mb={4}>Admin Login</Heading>
        {error && (
          <Alert status="error" mb={4}>
            <AlertIcon />
            {error}
          </Alert>
        )}
        {!challenge ? (
          <>
            <FormControl id="username" mb={4}>
              <FormLabel>Username</FormLabel>
              <Input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter your username"
              />
            </FormControl>
            <FormControl id="password" mb={4}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
            </FormControl>
            <Button colorScheme="teal" onClick={handleLogin} isLoading={loading} width="full">
              Login
            </Button>
          </>
        ) : (
          <>
            <FormControl id="newPassword" mb={4}>
              <FormLabel>New Password</FormLabel>
              <Input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter a new password"
              />
            </FormControl>
            <Button colorScheme="teal" onClick={handleNewPasswordSubmit} isLoading={loading} width="full">
              Submit New Password
            </Button>
          </>
        )}
      </Box>
    </>
  );
};

export default Login;
