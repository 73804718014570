
import  {API_BASE_URL} from '../constants'

// middleware.js
export const getAccessToken = () => {
    return localStorage.getItem("accessToken");
  };
export const convertToFloat = (data) => {
    return {
        ...data,
        discountAgainstDataExported: parseFloat(data.discountAgainstDataExported).toFixed(1), // Convert to float with 1 decimal
        discountAgainstDataProcessed: parseFloat(data.discountAgainstDataProcessed).toFixed(1), // Convert to float with 1 decimal
        discountAgainstDataStored: parseFloat(data.discountAgainstDataStored).toFixed(1), // Convert to float with 1 decimal
    };
};

  
 