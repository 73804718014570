import { Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Spinner, Text } from '@chakra-ui/react';

const OriginalAmountTable = ({ originalAmounts, isLoading, isError }) => {
   
    const { 
        original_cost_for_data_processed,
        original_cost_for_data_exported,
        original_cost_for_data_stored
    } = originalAmounts || {};

    
    const hasValidData = 
        original_cost_for_data_processed !== undefined &&
        original_cost_for_data_exported !== undefined &&
        original_cost_for_data_stored !== undefined;

    
    if (isLoading) {
        return (
            <Box padding="20px" textAlign="center">
                <Spinner size="lg" />
                <Text>Loading data...</Text>
            </Box>
        );
    }

    
    if (isError) {
        return (
            <Box padding="20px" textAlign="center">
                <Text color="red.500" fontWeight="bold">Error fetching data. Please try again.</Text>
            </Box>
        );
    }

    
    if (!hasValidData) {
        return (
            <Box padding="20px" textAlign="center">
                <Text>No data available.</Text>
            </Box>
        );
    }

    
    return (
        <Box padding="20px" width="250px">
            <h3 style={{ textAlign: "center", marginBottom: "10px", fontWeight: "bold" }}>
                Original Amount
            </h3>
            <TableContainer>
                <Table variant="unstyled" size="sm">
                    <Thead bg="blue.800">
                        <Tr>
                            <Th color="white" border="1px solid white" paddingX="5px">Name</Th>
                            <Th color="white" border="1px solid white" paddingX="5px">Amount</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">Processed Data</Td>
                            <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">{`$${parseFloat(original_cost_for_data_processed).toFixed(2)}`}</Td>
                        </Tr>
                        <Tr>
                            <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">Exported Data</Td>
                            <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">{`$${parseFloat(original_cost_for_data_exported).toFixed(2)}`}</Td>
                        </Tr>
                        <Tr>
                            <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">Stored Data</Td>
                            <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">{`$${parseFloat(original_cost_for_data_stored).toFixed(2)}`}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default OriginalAmountTable;
