import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";

const EditScriptModal = ({ isOpen, onClose, scriptVersion, onSave }) => {
  const [updatedScript, setUpdatedScript] = useState("");
  const [s3Url, setS3Url] = useState("");

  useEffect(() => {
    if (scriptVersion) {
      setUpdatedScript(scriptVersion.scriptVersion); // Initialize with current script version
      setS3Url(scriptVersion.s3Url || ""); // Initialize with current s3Url
    }
  }, [scriptVersion]);

  const handleSave = () => {
    // Ensure s3Url is included in the save operation
    const updatedData = {
      ...scriptVersion,
      scriptVersion: updatedScript,
      s3Url, // Include s3Url in the object being passed
    };

    console.log("Payload being sent:", updatedData); // Debugging line to check the payload
    onSave(updatedData); // Pass the updated data back to the parent component
    onClose(); // Close the modal
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Script Version</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Script Version</FormLabel>
            <Input
              value={updatedScript}
              onChange={(e) => setUpdatedScript(e.target.value)}
              placeholder="Enter updated script version"
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>S3 URL</FormLabel>
            <Input
              value={s3Url}
              onChange={(e) => setS3Url(e.target.value)}
              placeholder="Enter S3 URL"
              type="url" // Specify type as url for better input validation
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditScriptModal;
