import {
    Box,
    Flex,
    Avatar,
    HStack,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useDisclosure,
    useColorModeValue,
  } from '@chakra-ui/react';
  import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
  import { useNavigate } from 'react-router-dom'; // Import useNavigate
  
  
  function NavMenu({ isAuthenticated, logoutUser }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate(); // Initialize the useNavigate hook
  
    const handleLogout = async () => {
      await logoutUser(); // Call the logout function
      navigate('/login'); // Redirect to the login page
    };
  
    return (
      <>
        <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            {/* Mobile Menu Button */}
            <IconButton
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
            {/* Logo and Links */}
            <HStack spacing={8} alignItems={'center'}>
              <Box>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/logo.png`}
                  alt="Logo"
                  className="App-logo"
                  style={{ height: '50px' }}
                />
              </Box>
              {/* Desktop Links */}
              <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
              </HStack>
            </HStack>
            {/* Profile Avatar Menu */}
            {isAuthenticated && (
              <Flex alignItems={'center'}>
                <Menu>
                  <MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
                    <Avatar
                      size={'sm'}
                      src={
                        'https://www.pngitem.com/pimgs/m/516-5167304_transparent-background-white-user-icon-png-png-download.png'
                      }
                      alt="User Avatar"
                    />
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem> {/* Call handleLogout on click */}
                  </MenuList>
                </Menu>
              </Flex>
            )}
          </Flex>
  
          {/* Mobile Menu Links */}
          {isOpen ? (
            <Box pb={4} display={{ md: 'none' }}>

            </Box>
          ) : null}
        </Box>
      </>
    );
  }
  
  export default NavMenu;
  