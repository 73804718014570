import { getAccessToken,convertToFloat } from "./apiServices";
import { API_BASE_URL } from "./constants";


// src/api.js
export const fetchCoupons = async () => {
    try {
        const accessToken = getAccessToken(); 
        if (!accessToken) {
            throw new Error("No access token found. Please log in again.");
        }

        const response = await fetch(`${API_BASE_URL}/v1/coupons/get-all`, {
            method: 'GET',
            headers: {
                'Authorization': accessToken,
                // Commenting out other headers for testing
            },
            mode: 'cors',
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to fetch coupons: ${errorData.detail || 'An error occurred.'}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Fetch error:", {error, message:error.message});
        throw error; // Rethrow if you want to handle it upstream
    }
};





export const updateCouponStatus = async (couponId, startDate, isActive) => {
    const accessToken = getAccessToken(); // Use middleware to get the token
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }

    const response = await fetch(`${API_BASE_URL}/v1/coupons/update?couponId=${couponId}&startDate=${startDate}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
        mode: 'cors',
        body: JSON.stringify({ isActive }),
    });

    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to update coupon status: ${errorDetails.message}`);
    }

    return await response.json(); 
};

export const deleteCoupon = async (couponId, startDate) => {
    const accessToken = getAccessToken(); // Use middleware to get the token
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }
    const response = await fetch(`${API_BASE_URL}/v1/coupons/delete?couponId=${couponId}&startDate=${startDate}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':accessToken
        },
        mode: 'cors',
    });

    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to delete coupon: ${errorDetails.message}`);
    }

    return await response.json(); // Optionally return a success message
};

export const fetchCouponDetails = async (couponId, startDate) => {
    const accessToken = getAccessToken(); // Use middleware to get the token
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }
    const response = await fetch(`${API_BASE_URL}/v1/coupons/get-one?couponId=${couponId}&startDate=${startDate}`,
        {
            method: 'GET', // Specify the method if necessary
            headers: {
                'Content-Type': 'application/json',
                'Authorization':accessToken
            },
            mode: 'cors', // Use 'cors' mode to allow cross-origin requests
        }
    );
    if (!response.ok) {
        throw new Error('Failed to fetch coupon details');
    }
    return await response.json();
};

export const updateCoupon = async (couponId, startDate, updatedDetails) => {
    const accessToken = getAccessToken(); // Use middleware to get the token
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }
    console.log(updatedDetails,"updatedDetails")
    // const updatedDetailsWithFloats = convertToFloat(updatedDetails);
    const response = await fetch(`${API_BASE_URL}/v1/coupons/update?couponId=${couponId}&startDate=${startDate}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
        mode: 'cors', 
        body: JSON.stringify(updatedDetails),
    });

    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to update coupon: ${errorDetails.message}`);
    }

    return await response.json();
};

// campaign APIs 

export const createCampaign = async (campaignData) => {
    const accessToken = getAccessToken();
    try {
      const response = await fetch(`${API_BASE_URL}/v1/campaigns/create`, {
        method: 'POST',
        headers: {
            'Authorization': `${accessToken}`, 
          },
        body: campaignData,
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error creating campaign: ${errorData.detail}`);
      }
  
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error creating campaign:', error);
      throw error;
    }
  };
  
  

export const getAllCampaigns = async () => {
    const accessToken = getAccessToken(); // Use middleware to get the token
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }
    try {
      const response = await fetch(`${API_BASE_URL}/v1/campaigns/get-all`, {
        method: 'GET', // Use POST as defined in your backend
        headers: {
          'Content-Type': 'application/json',
          'Authorization':accessToken,
        },
        mode:"cors"
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error fetching campaigns: ${errorData.detail}`);
      }
  
      const campaigns = await response.json();
      return campaigns;
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      throw error; // Rethrow the error to handle it in the component
    }
  };
  
  export const deleteCampaign = async (campaignId) => {
    const accessToken = getAccessToken(); // Use middleware to get the token
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }
    const response = await fetch(`${API_BASE_URL}/v1/campaigns/delete?campaignId=${campaignId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':accessToken,
          },
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error deleting campaign: ${errorData.detail}`);
    }

    return await response.json(); // Return the response, which might contain details about the deleted campaign
};

export const fetchCampaignById = async (campaignId) => {
    const accessToken = getAccessToken(); // Use middleware to get the token
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }
    const response = await fetch(`${API_BASE_URL}/v1/campaigns/get-one?campaignId=${campaignId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':accessToken
        },
        
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error fetching campaign: ${errorData.detail}`);
    }

    return await response.json(); 
};

export const updateCampaign = async (campaignId, updatedData) => {
    const accessToken = getAccessToken(); 
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }
    const response = await fetch(`${API_BASE_URL}/v1/campaigns/update?campaignId=${campaignId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':accessToken
        },
        body: JSON.stringify(updatedData), 
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error updating campaign: ${errorData.detail}`);
    }

    return await response.json(); 
};

export const sendOfferEmail = async (campaignId) => {
    const accessToken = getAccessToken(); // Use middleware to get the token
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }
    const response = await fetch(`${API_BASE_URL}/v1/campaigns/send-email?campaignId=${campaignId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':accessToken
        },
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error sending email: ${errorData.detail}`);
    }

    return await response.json();
};


export const getCost = async () => {
    try {
        const accessToken = getAccessToken(); 
        if (!accessToken) {
            throw new Error("No access token found. Please log in again.");
        }

        const response = await fetch(`${API_BASE_URL}/v1/get-original-costs`, {
            method: 'GET',
            headers: {
                'Authorization': accessToken,
               
            },
            mode: 'cors',
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to fetch coupons: ${errorData.detail || 'An error occurred.'}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Fetch error:", {error, message:error.message});
        throw error; // Rethrow if you want to handle it upstream
    }
};

//api for individual user's details

export const getIUserDetails = async (customerIdentifier) => {
    try {
        const accessToken = getAccessToken();
        if(!accessToken){
            throw new Error("No access token found. Please login in again.")
        }
        const response = await fetch(`${API_BASE_URL}/v1/users/${customerIdentifier}`, {
            method: 'GET',
            headers: {
                'authorization' : accessToken,

            },
            mode: 'cors',
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to user details: ${errorData.detail || 'An error occurred.'}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Fetch error:", {error, message:error.message});
        throw error; // Rethrow if you want to handle it upstream
    }
}

//api for individual user's stats

export const getStat = async (customerIdentifier,customerAWSAccountID,startTimeStamp,endTimeStamp ) => {
    try {
        const accessToken = getAccessToken(); 
        if (!accessToken) {
            throw new Error("No access token found. Please log in again.");
        }

        const response = await fetch(`${API_BASE_URL}/v1/metering-stats?customerIdentifier=${customerIdentifier}&accountId=${customerAWSAccountID}&startTimestamp=${startTimeStamp}&endTimestamp=${endTimeStamp}`, {
            method: 'GET',
            headers: {
                'Authorization': accessToken,
               
            },
            mode: 'cors',
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to fetch coupons: ${errorData.detail || 'An error occurred.'}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Fetch error:", {error, message:error.message});
        throw error; // Rethrow if you want to handle it upstream
    }
};

//API for get script versions

export const getScriptVersions = async () => {
    const accessToken = getAccessToken(); // Use middleware to get the token
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }
    try {
        const response = await fetch(`${API_BASE_URL}/v1/get-cf-script-versions`, {
          method: 'GET', // Use POST as defined in your backend
          headers: {
            'Content-Type': 'application/json',
            'Authorization':accessToken,
          },
          mode:"cors"
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Error fetching script versions: ${errorData.detail}`);
        }
    
        const campaigns = await response.json();
        return campaigns;
      } catch (error) {
        console.error('Error fetching campaigns:', error);
        throw error; // Rethrow the error to handle it in the component
      }

    }

//API for create script version

export const createScriptVersion = async (scriptVersionData) => {
    const accessToken = getAccessToken();
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }

    try {
        const response = await fetch(`${API_BASE_URL}/v1/insert-script-version`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken,
            },
            mode: "cors",
            body: JSON.stringify(scriptVersionData),
        });

        if (!response.ok) {
            // Handle non-200 responses
            const errorData = await response.json();
            throw new Error(`Error creating script version: ${errorData.detail || 'Unknown error'}`);
        }

        // Attempt to parse JSON response; if it's empty, return null or an empty object
        const data = await response.json().catch(() => {
            console.warn("Response was not in JSON format or was empty");
            return null;
        });

        return data;
    } catch (error) {
        console.error('Error in createScriptVersion function:', error);
        throw error;
    }
};


//API for get one script version

export const getOneScriptVersion = async (scriptId) => {
    const accessToken = getAccessToken(); // Use middleware to get the token
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }
    try {
        const response = await fetch(`${API_BASE_URL}/v1/get-one-cf-script-version?scriptId=${scriptId}`, {
          method: 'GET', // Use POST as defined in your backend
          headers: {
            'Content-Type': 'application/json',
            'Authorization':accessToken,
          },
          mode:"cors"
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Error fetching script versions: ${errorData.detail}`);
        }
    
        const campaigns = await response.json();
        return campaigns;
      } catch (error) {
        console.error('Error fetching campaigns:', error);
        throw error; // Rethrow the error to handle it in the component
      }

    }

//API for delete script versions

export const deleteScriptVersion = async (scriptId) => {
    const accessToken = getAccessToken(); // Use middleware to get the token
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }
    try {
        const response = await fetch(`${API_BASE_URL}/v1/delete-script-version?scriptId=${scriptId}`, {
          method: 'DELETE', // Use POST as defined in your backend
          headers: {
            'Content-Type': 'application/json',
            'Authorization':accessToken,
          },
          mode:"cors"
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Error fetching script versions: ${errorData.detail}`);
        }
    
        const campaigns = await response.json();
        return campaigns;
      } catch (error) {
        console.error('Error fetching campaigns:', error);
        throw error; // Rethrow the error to handle it in the component
      }

    }

//API for update script versions

export const updateScriptVersion = async (scriptId, updatedScriptData) => {
    const accessToken = getAccessToken(); // Use middleware to get the token
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }
    try {
        const response = await fetch(`${API_BASE_URL}/v1/update-script-version?scriptId=${scriptId}`, {
          method: 'PUT', // Use POST as defined in your backend
          headers: {
            'Content-Type': 'application/json',
            'Authorization':accessToken,
          },
          mode:"cors",
          body: JSON.stringify(updatedScriptData)
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Error fetching script versions: ${errorData.detail}`);
        }
    
        const campaigns = await response.json();
        return campaigns;
      } catch (error) {
        console.error('Error fetching campaigns:', error);
        throw error; // Rethrow the error to handle it in the component
      }

    }


//API for update script versions in userstable

export const updateScripts =async (customerIdentifier, newscriptVersion) => {
    const accessToken = getAccessToken(); // Use middleware to get the token
    if (!accessToken) {
        throw new Error("No access token found. Please log in again.");
    }
    try{
        const response = await fetch (`${API_BASE_URL}/v1/update-cf-script?customerIdentifier=${customerIdentifier}&newScriptVersion=${newscriptVersion}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'authorization': accessToken, 
            },
            mode:'cors'
        });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error fetching script versions: ${errorData.detail}`);
      }
  
      const campaigns = await response.json();
      return campaigns;
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      throw error; // Rethrow the error to handle it in the component
    }
}

//Deployement

export const triggerUIDeployment = async () => {
    try {
        const accessToken = getAccessToken();
        if (!accessToken) {
            throw new Error("No access token found. Please log in again.");
        }

        const response = await fetch(`${API_BASE_URL}/v1/trigger-ui-deployment`, {
            method: 'GET',
            headers: {
                'Authorization': accessToken,
                // Additional headers can be added here if needed
            },
            mode: 'cors',
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to trigger UI deployment: ${errorData.detail || 'An error occurred.'}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Fetch error:", {error, message: error.message});
        throw error;
    }
};


