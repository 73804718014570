import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
  Select,
  FormHelperText,
} from '@chakra-ui/react';
import { fetchCoupons } from '../api'; // Import the fetchCoupons function
function CreateCompaign({
  isOpen,
  onClose,
  couponCode,
  setCouponCode,
  campaignName,
  setCampaignName,
  file,
  setFile,
  handleCreateCampaign,
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [coupons, setCoupons] = useState([]);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const couponsData = await fetchCoupons();
        setCoupons(couponsData); 
      } catch (error) {
        setErrorMessage('Failed to fetch coupons. Please try again later.');
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
      setErrorMessage('Only CSV files are allowed. Please upload a valid CSV file.');
      setFile(null); // Reset the file state
      return;
    }
    setFile(file);
    setErrorMessage(''); // Clear error message if file is valid
  };

  // Handler for form submission
  const handleSubmit = async () => {
    if (!campaignName || !file || !couponCode) {
      setErrorMessage('Please fill in all required fields.');
      return;
    }

    setErrorMessage('');
    handleCreateCampaign();
  };

  useEffect(() => {
    // Clear error messages when modal opens or closes
    if (!isOpen) setErrorMessage('');
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="800px">
        <ModalHeader>Add New Campaign</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Campaign Name Field */}
          <FormControl isRequired mb={4}>
            <FormLabel>Campaign Name</FormLabel>
            <Input
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              placeholder="Enter Campaign Name"
              maxLength={20}
            />
             <FormHelperText>{`${campaignName.length}/20 characters`}</FormHelperText>
          </FormControl>

          {/* CSV File Upload Field */}
          <FormControl isRequired mb={4}>
            <FormLabel>Upload CSV File</FormLabel>
            <Input type="file" accept=".csv" onChange={handleFileChange} pt={1} />
            {file && <Text mt={2}>Selected File: {file.name}</Text>}
          </FormControl>

          
          <FormControl isRequired mb={4}>
  <FormLabel>Coupon Code</FormLabel>
  <Select
    placeholder="Select Coupon Code"
    value={couponCode} // Assuming couponCode is the state holding the selected couponId
    onChange={(e) => setCouponCode(e.target.value)} // Set couponCode to the selected couponId
  >
    {coupons.map((coupon) => (
      <option key={coupon.couponId} value={coupon.couponId}>
        {coupon.couponCode}
      </option>
    ))}
  </Select>
</FormControl>

          {/* Error Message Display */}
          {errorMessage && <Text color="red.500">{errorMessage}</Text>}
        </ModalBody>

        <ModalFooter>
          {/* Submit Button */}
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Submit
          </Button>
          {/* Close Button */}
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CreateCompaign;
