import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Alert,
  AlertIcon,
  Button,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import {
  getScriptVersions,
  getOneScriptVersion,
  deleteScriptVersion,
  updateScriptVersion,
} from "../api";
import EditScriptModal from "./EditScriptModal";
import { useAuth } from "../context/authContext";

const ScriptVersionListTable = () => {
  const { logout } = useAuth();
  const [scriptVersions, setScriptVersions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedScriptVersion, setSelectedScriptVersion] = useState(null);

  // Fetch data from the API
  const fetchData = async () => {
    setLoading(true); // Start loading
    try {
      const versions = await getScriptVersions();
      setScriptVersions(versions);
    } catch (err) {
      if (
        err.message.includes("Unauthorized") ||
        err.message.includes("Forbidden")
      ) {
        await logout();
        setError(
          "Your session has expired or you do not have permission to access this resource. Please log in again."
        );
      } else {
        setError(`Error: ${err.message}`);
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = async (scriptVersion) => {
    try {
      const detailedVersion = await getOneScriptVersion(scriptVersion.scriptId);
      setSelectedScriptVersion(detailedVersion);
      setEditModalOpen(true);
    } catch (err) {
      if (
        err.message.includes("Unauthorized") ||
        err.message.includes("Forbidden")
      ) {
        await logout();
        setError(
          "Your session has expired or you do not have permission to access this resource. Please log in again."
        );
      } else {
        setError(`Error: ${err.message}`);
      }
    }
  };

  const handleDelete = async (scriptId) => {
    try {
      await deleteScriptVersion(scriptId);
      setScriptVersions((prevVersions) =>
        prevVersions.filter((version) => version.scriptId !== scriptId)
      );
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSave = async (updatedVersion) => {
    try {
      await updateScriptVersion(updatedVersion.scriptId, {
        scriptVersion: updatedVersion.scriptVersion,
        s3Url: updatedVersion.s3Url,
      });
      await fetchData();
      setEditModalOpen(false);
    } catch (err) {
      if (
        err.message.includes("Unauthorized") ||
        err.message.includes("Forbidden")
      ) {
        await logout();
        setError(
          "Your session has expired or you do not have permission to access this resource. Please log in again."
        );
      } else {
        setError(`Error: ${err.message}`);
      }
    }
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        {error && (
          <Box textAlign="center" p={4} color="red.500">
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          </Box>
        )}

        {loading ? ( // Show spinner while loading
          <Flex justifyContent="center" alignItems="center" minHeight="50vh">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <>
            <h3
              style={{
                textAlign: "center",
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              CloudFront Scripts
            </h3>

            <TableContainer maxHeight="50vh" overflowY="auto">
              <Table variant="unstyled" size="sm">
                <Thead bg="blue.800">
                  <Tr>
                    <Th color="white" border="1px solid white">
                      Script Version
                    </Th>
                    <Th color="white" border="1px solid white">
                      Date
                    </Th>
                    <Th color="white" border="1px solid white">
                      s3Url
                    </Th>
                    <Th color="white" border="1px solid white" textAlign="center">
                      Actions
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {scriptVersions.length === 0 ? (
                    <Tr>
                      <Td colSpan={4} textAlign="center" border="1px solid #CBD5E0">
                        No script versions available.
                      </Td>
                    </Tr>
                  ) : (
                    scriptVersions.map((version) => (
                      <Tr key={version.scriptId}>
                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                          {version.scriptVersion}
                        </Td>
                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                          {new Date(version.datetime).toLocaleDateString()}
                        </Td>
                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                          {version.s3Url}
                        </Td>
                        <Td border="1px solid #CBD5E0" paddingY="2px" paddingX="10px">
                          <Flex justifyContent="space-around">
                            <Button
                              colorScheme="blue"
                              onClick={() => handleEdit(version)}
                              size="sm"
                            >
                              Edit
                            </Button>
                            <Button
                              colorScheme="red"
                              onClick={() => handleDelete(version.scriptId)}
                              size="sm"
                            >
                              Delete
                            </Button>
                          </Flex>
                        </Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>

      {selectedScriptVersion && (
        <EditScriptModal
          isOpen={isEditModalOpen}
          onClose={() => setEditModalOpen(false)}
          scriptVersion={selectedScriptVersion}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default ScriptVersionListTable;
