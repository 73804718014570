import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  Box,
} from "@chakra-ui/react";

const TimeRangeModal = ({
  isOpen,
  onClose,
  startTimeStamp,
  setStartTimeStamp,
  endTimeStamp,
  setEndTimeStamp,
  onProceed,
  isLoading,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Provide Time Range</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <Input
              placeholder="Start Timestamp (YYYY-MM-DD)"
              type="date"
              value={startTimeStamp}
              onChange={(e) => setStartTimeStamp(e.target.value)}
            />
          </Box>
          <Box>
            <Input
              placeholder="End Timestamp (YYYY-MM-DD)"
              type="date"
              value={endTimeStamp}
              onChange={(e) => setEndTimeStamp(e.target.value)}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onProceed} isLoading={isLoading}>
            Proceed
          </Button>
          <Button onClick={onClose} ml={3}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TimeRangeModal;
