import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { getScriptVersions, updateScripts } from "../api"; 
const DeployScriptModal = ({
  isOpen,
  onClose,
  currentVersion,
  customerIdentifier, 
}) => {
  const [selectedVersion, setSelectedVersion] = useState("");
  const [availableVersions, setAvailableVersions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const hasFetched = useRef(false);

 
  useEffect(() => {
    const fetchScriptVersions = async () => {
      setLoading(true);
      setError(null); 

      try {
        const versions = await getScriptVersions();
        setAvailableVersions(versions); 
        hasFetched.current = true; 
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false); 
      }
    };

    if (isOpen) {
      hasFetched.current = false; 
      fetchScriptVersions(); 
    }
  }, [isOpen]);

  const handleVersionChange = (e) => {
    setSelectedVersion(e.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedVersion) return; 

    try {
      await updateScripts(customerIdentifier, selectedVersion); 
      console.log("Successfully updated script version to:", selectedVersion);
      onClose();
    } catch (error) {
      console.error("Error updating script version:", error);
      setError(error.message || "Failed to update script version.");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="transparent" />
      <ModalContent>
        <ModalHeader>Deploy Script</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Current Script Version</FormLabel>
            <Input value={currentVersion} isReadOnly />
          </FormControl>
          <FormControl>
            <FormLabel>Select Version to Deploy</FormLabel>
            {loading ? (
              <p>Loading versions...</p>
            ) : error ? (
              <p>Error fetching versions: {error}</p>
            ) : (
              <Select
                placeholder="Select version"
                onChange={handleVersionChange}
                value={selectedVersion}
              >
                {availableVersions.map((version) => (
                  <option key={version.scriptId} value={version.scriptVersion}>
                    {version.scriptVersion}
                  </option>
                ))}
              </Select>
            )}
          </FormControl>
          {error && <p style={{ color: "red" }}>{error}</p>}{" "}
          {/* Display error message if any */}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
            isDisabled={!selectedVersion}
          >
            Submit
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeployScriptModal;
