/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f222295e-694a-4baa-b1a4-fc2aac6da058",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_EXWDT218V",
    "aws_user_pools_web_client_id": "4d651il2mhaocmp4qete80dmls",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "Auth": {
        "userPoolId": "us-east-1_hi377EozQ",
        "userPoolWebClientId": "6gsh9onlaikp66uk6g6bi285ip"
    }
};


export default awsmobile;
