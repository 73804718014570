import React, { createContext, useEffect, useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { Box, Spinner, useToast } from '@chakra-ui/react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const logout = async () => {
    try {
        // Clear the token from local storage
        localStorage.removeItem('accessToken'); // Adjust the key as necessary
        await Auth.signOut();
        setIsAuthenticated(false);
        // Show toast message for session expiration
        // toast({
        //   id: 'session-expired-toast',
        //   title: 'Session Expired',
        //   description: 'Your session has expired. Please log in again.',
        //   status: 'warning',
        //   duration: 5000,
        //   isClosable: true,
        // });
        navigate('/login'); // Navigate to login after logout
    } catch (error) {
        console.error('Error during logout:', error);
    }
    console.log("logout function is called")
};

useEffect(() => {
  const checkUser = async () => {
    try {
      await Auth.currentSession();
      setIsAuthenticated(true);
      setToastDisplayed(false); // Reset toast displayed state
    } catch (error) {
      console.error('User is not authenticated or token has expired:', error);
      setIsAuthenticated(false);
      
      if (!toastDisplayed) {
        setToastDisplayed(true); // Mark toast as displayed
        toast({
          id: 'session-expired-toast',
          title: 'Session Expired',
          description: 'Your session has expired. Please log in again.',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
      }
      
      navigate('/login'); // Navigate to login if not authenticated
    } finally {
      setLoading(false);
    }
  };

  checkUser();
}, [navigate, toastDisplayed]);
// Ensure toast is in dependencies

  if (loading) {
    return (
      <Box
        minH="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Create a custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);
