import React from "react";
import ReactApexChart from "react-apexcharts";

function ApexChart({ statsData }) {
  // Check if statsData is available and has the necessary properties
  if (
    !statsData ||
    !Array.isArray(statsData.UploadRecords) ||
    !Array.isArray(statsData.StorageRecords) ||
    !Array.isArray(statsData.ExportedRecords)
  ) {
    return <div>No Data Available</div>; // Handle loading state
  }

  const options = {
    chart: {
      type: "line",
      height: 350,
      animations: {
        enabled: true,
      },
      zoom: {
        enabled: true,
        type: "y",
      },
    },
    theme: {
      mode: "light",
    },
    title: {
      text: "Files Over Time",
      align: "left",
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "MMM dd",
      },
    },
    yaxis: {
      title: {
        text: "Files in GBs",
      },
    },
    tooltip: {
      shared: true,
      x: {
        format: "MMMM dd HH:mm",
      },
    },
    legend: {
      show: true,
      onItemClick: {
        toggleDataSeries: true, // Toggle series visibility on legend item click
      },
    },
    stroke: {
      curve: "stepline",
    },
    colors: ["#008FFB", "#00E396", "#FF4560"],
  };

  const series = [
    {
      name: "Upload Files in GBs",
      data: statsData.UploadRecords.map((record) => [
        new Date(record.timestamp).getTime(),
        record.quantity,
      ]),
    },
    {
      name: "Storage Files in GBs",
      data: statsData.StorageRecords.map((record) => [
        new Date(record.timestamp).getTime(),
        record.quantity,
      ]),
    },
    {
      name: "Exported Files in GBs",
      data: statsData.ExportedRecords.map((record) => [
        new Date(record.timestamp).getTime(),
        record.quantity,
      ]),
    },
  ];

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
        className="w-full"
      />
    </div>
  );
}

export default ApexChart;
