import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Box,
    Spinner,
    Text,
    HStack,
} from '@chakra-ui/react';
import { fetchCoupons, fetchCampaignById, updateCampaign } from '../api'; // Import necessary functions
import { useAuth } from '../context/authContext';

const EditCampaignModal = ({ isOpen, onClose, campaign, onUpdate }) => {
    const { logout } = useAuth();
    const [formData, setFormData] = useState(campaign);
    const [couponIds, setCouponIds] = useState([]);
    const [csvFile, setCsvFile] = useState(null); // State to hold the uploaded file
    const [existingCsvFile, setExistingCsvFile] = useState(null); // State to hold existing CSV file details
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch campaign details by ID and available coupons when modal opens
    useEffect(() => {
        const getCampaignData = async () => {
            setLoading(true);
            try {
                // Fetch campaign details including the existing CSV file information
                const campaignData = await fetchCampaignById(campaign.campaignId);
                setFormData(campaignData);

                // Set the existing CSV file name from the campaign data
                setExistingCsvFile(campaignData.fileName || null);
            } catch (err) {
                setError(err.message);
                if (err.message.includes("Unauthorized") || err.message.includes("Forbidden")) {
                    await logout();
                    setError("Your session has expired or you do not have permission to access this resource. Please log in again.");
                } else {
                    setError(`Error: ${err.message}`);
                }
            } finally {
                setLoading(false);
            }
        };

        const getCouponIds = async () => {
            try {
                const couponsData = await fetchCoupons();
                setCouponIds(couponsData); // Set coupons data for dropdown
            } catch (err) {
                setError(`Error fetching coupons: ${err.message}`);
            }
        };

        if (isOpen) {
            getCampaignData(); // Fetch campaign data when modal opens
            getCouponIds(); // Fetch available coupons when modal opens
            setCsvFile(null); // Reset CSV file when the modal is reopened
        }
    }, [isOpen, campaign.campaignId]);

    // Handle form input changes
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle CSV file input change
    const handleFileChange = (event) => {
        const [file] = event.target.files;
        if (!file) return

        setCsvFile(file); // Store the new file in state
        setExistingCsvFile(file ? file.name : existingCsvFile); // Update displayed file name if a new file is selected
    };

    // Handle form submission
    const handleSubmit = async () => {
        try {
            const updatedData = { ...formData };

            if (csvFile) {
                const formDataToSend = new FormData();
                formDataToSend.append('csvFile', csvFile);
                formDataToSend.append('campaignData', JSON.stringify(updatedData));
                
                await updateCampaign(updatedData.campaignId, formDataToSend);
            } else {
                await updateCampaign(updatedData.campaignId, updatedData);
            }

            onUpdate(updatedData);
            onClose(); 
        } catch (error) {
            console.error('Error updating campaign:', error);
            setError(error.message);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit Campaign</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {loading && (
                        <Box textAlign="center" p={4}>
                            <Spinner />
                        </Box>
                    )}
                    {error && <Box color="red.500">{error}</Box>}
                    {formData && !loading && (
                        <>
                            <FormControl>
                                <FormLabel>Campaign Name</FormLabel>
                                <Input
                                    name="campaignName"
                                    value={formData.campaignName || ''}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl mt={4}>
                                <FormLabel>Coupon ID</FormLabel>
                                <Select
                                    name="couponId"
                                    value={formData.couponId || ''}
                                    onChange={handleChange}
                                >
                                    {couponIds.map((id) => (
                                        <option key={id.couponId} value={id.couponId}>
                                            {id.couponCode}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* File Upload Field */}
                            <FormControl mt={4}>
                                <FormLabel>Upload CSV File</FormLabel>
                                <HStack>
                                    {/* Custom file upload button */}
                                    <Button as="label" htmlFor="file-upload" cursor="pointer">
                                        Choose File
                                    </Button>
                                    {/* Hidden input field for file upload */}
                                    <Input
                                        id="file-upload"
                                        type="file"
                                        accept=".csv"
                                        display="none"
                                        onChange={handleFileChange}
                                    />
                                    {/* Display the existing or newly selected file name */}
                                    <Text>{existingCsvFile ? existingCsvFile : 'No file selected'}</Text>
                                </HStack>
                            </FormControl>
                        </>
                    )}
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" onClick={handleSubmit}>
                        Save
                    </Button>
                    <Button variant="ghost" onClick={onClose} ml={3}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default EditCampaignModal;
