import React, { useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Spinner,
    Box,
    Switch,
    Button,
    Input,
    Alert,
    AlertIcon,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import EditCouponModal from './EditCouponModal';
import { fetchCoupons, updateCouponStatus, deleteCoupon, fetchCouponDetails } from '../api';
import { useAuth } from '../context/authContext'; 

const CouponListTable = ({ costAmounts }) => {
    const { logout } = useAuth();
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState({ couponId: '', startDate: '' });
    const [activeStatus, setActiveStatus] = useState(null);
   

    // Destructure the cost amounts
    const { 
        original_cost_for_data_processed,
        original_cost_for_data_exported,
        original_cost_for_data_stored
    } = costAmounts || {};

    const loadCoupons = async () => {
        setLoading(true);
        try {
            const data = await fetchCoupons();
            setCoupons(data);
        } catch (err) {
            console.error('Error fetching coupons:', err.message);
            if (err.message.includes("Unauthorized") || err.message.includes("Forbidden")) {
                await logout();
                setError("Your session has expired or you do not have permission to access this resource. Please log in again.");
            } else {
                setError(`Error: ${err.message}`);
            }
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        loadCoupons();
    }, [logout]);

    const handleCouponUpdate = () => {
        loadCoupons();  // Re-fetch all coupons after a successful update
    };


    useEffect(() => {
        if (activeStatus !== null) {
            setCoupons(prevCoupons =>
                prevCoupons.map(coupon =>
                    coupon.couponId === selectedCoupon.couponId ? { ...coupon, isActive: activeStatus } : coupon
                )
            );
            setActiveStatus(null);
        }
    }, [activeStatus, selectedCoupon]);

    const handleToggleActive = async (couponId, startDate, currentStatus) => {
        const newStatus = !currentStatus;
        try {
            await updateCouponStatus(couponId, startDate, newStatus);
            setCoupons(prevCoupons =>
                prevCoupons.map(coupon =>
                    coupon.couponId === couponId ? { ...coupon, isActive: newStatus } : coupon
                )
            );
            setSelectedCoupon({ couponId, startDate });
        } catch (err) {
            console.error('Error updating coupon status:', error);
            if (err.message.includes("Unauthorized") || err.message.includes("Forbidden")) {
                await logout();
                setError("Your session has expired or you do not have permission to access this resource. Please log in again.");
            } else {
                setError(`Error: ${err.message}`);
            }
        }
    };

    const handleEditClick = async (couponId, startDate) => {
        try {
            const couponDetails = await fetchCouponDetails(couponId, startDate);
            setSelectedCoupon(couponDetails);
            setEditModalOpen(true);
        } catch (error) {
            console.error('Error fetching coupon details:', error);
        }
    };

    const handleDeleteCoupon = async (couponId, startDate) => {
        try {
            await deleteCoupon(couponId, startDate);
            setCoupons(prevCoupons => prevCoupons.filter(coupon => coupon.couponId !== couponId || coupon.startDate !== startDate));
        } catch (error) {
            console.error('Error deleting coupon:', error);
        }
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredCoupons = coupons?.filter(coupon => {
        return (
            coupon.couponName?.toLowerCase().includes(searchTerm) ||
            coupon.couponCode?.toLowerCase().includes(searchTerm) ||
            coupon.sellerName?.toLowerCase().includes(searchTerm)
        );
    });

    if (loading) {
        return (
            <Box textAlign="center" p={4}>
                <Spinner />
            </Box>
        );
    }

    if (error) {
        return (
            <Box textAlign="center" p={4} color="red.500">
                <Alert status="error">
                    <AlertIcon />
                    {error}
                </Alert>
            </Box>
        );
    }

    return (
        <>
            <div style={{ padding: "20px" }}>
                <h3 style={{ textAlign: "center", marginBottom: "10px", fontWeight: "bold" }}>
                    Generate Coupons
                </h3>

                <Input
                    placeholder="Search by Coupon Name, Code, or Seller"
                    value={searchTerm}
                    onChange={handleSearch}
                    marginBottom="20px"
                />

                <Box maxHeight="50vh" overflowY="auto">
                    <TableContainer>
                        <Table variant="unstyled" size="sm">
                            <Thead bg="blue.800">
                                <Tr>
                                    <Th color="white" border="1px solid white" paddingX="5px">Coupon Name</Th>
                                    <Th color="white" border="1px solid white" paddingX="5px">Coupon Code</Th>
                                    <Th color="white" border="1px solid white" paddingX="5px">Start Date</Th>
                                    <Th color="white" border="1px solid white" paddingX="5px">Expiry Date</Th>
                                    <Th color="white" border="1px solid white" paddingX="5px">Seller Name</Th>
                                    <Th color="white" border="1px solid white" paddingX="5px">
                                        <Grid templateRows="repeat(2, 1fr)" gap={1}>
                                            <GridItem><strong>Discount (Stored)</strong></GridItem>
                                            <Grid templateColumns="1fr 1fr">
                                                <GridItem textAlign="center">%</GridItem>
                                                <GridItem textAlign="center">$</GridItem>
                                            </Grid>
                                        </Grid>
                                    </Th>
                                    <Th color="white" border="1px solid white" paddingX="5px">
                                        <Grid templateRows="repeat(2, 1fr)" gap={1}>
                                            <GridItem><strong>Discount (Exported)</strong></GridItem>
                                            <Grid templateColumns="1fr 1fr">
                                                <GridItem textAlign="center">%</GridItem>
                                                <GridItem textAlign="center">$</GridItem>
                                            </Grid>
                                        </Grid>
                                    </Th>
                                    <Th color="white" border="1px solid white" paddingX="5px">
                                        <Grid templateRows="repeat(2, 1fr)" gap={1}>
                                            <GridItem><strong>Discount (Processed)</strong></GridItem>
                                            <Grid templateColumns="1fr 1fr">
                                                <GridItem textAlign="center">%</GridItem>
                                                <GridItem textAlign="center">$</GridItem>
                                            </Grid>
                                        </Grid>
                                    </Th>
                                    <Th color="white" border="1px solid white" isNumeric paddingX="5px">Active</Th>
                                    <Th color="white" border="1px solid white" paddingX="5px">Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredCoupons?.map((coupon) => (
                                    <Tr key={coupon.couponId}>
                                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">{coupon.couponName}</Td>
                                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">{coupon.couponCode}</Td>
                                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">{coupon.startDate}</Td>
                                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">{coupon.expiryDate}</Td>
                                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">{coupon.sellerName}</Td>
                                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                                            <Grid templateColumns="1fr 1fr" gap={2}>
                                                <GridItem textAlign="center">{coupon.discountAgainstDataStored ? `${coupon.discountAgainstDataStored}%` : 'N/A'}</GridItem>
                                                <GridItem textAlign="center">
                                                    {coupon.discountAgainstDataStored
                                                        ? `$${(parseFloat(original_cost_for_data_stored) - (parseFloat(original_cost_for_data_stored) * parseFloat(coupon.discountAgainstDataStored) / 100)).toFixed(2)}` 
                                                        : 'N/A'}
                                                </GridItem>
                                            </Grid>
                                        </Td>
                                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                                            <Grid templateColumns="1fr 1fr" gap={2}>
                                                <GridItem textAlign="center">{coupon.discountAgainstDataExported ? `${coupon.discountAgainstDataExported}%` : 'N/A'}</GridItem>
                                                <GridItem textAlign="center">
                                                    {coupon.discountAgainstDataExported 
                                                        ? `$${(parseFloat(original_cost_for_data_exported) - (parseFloat(original_cost_for_data_exported) * parseFloat(coupon.discountAgainstDataExported) / 100)).toFixed(2)}` 
                                                        : 'N/A'}
                                                </GridItem>
                                            </Grid>
                                        </Td>
                                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                                            <Grid templateColumns="1fr 1fr" gap={2}>
                                                <GridItem textAlign="center">{coupon.discountAgainstDataProcessed ? `${coupon.discountAgainstDataProcessed}%` : 'N/A'}</GridItem>
                                                <GridItem textAlign="center">{coupon.discountAgainstDataProcessed 
                                                    ? `$${(parseFloat(original_cost_for_data_processed) - (parseFloat(original_cost_for_data_processed) * parseFloat(coupon.discountAgainstDataProcessed) / 100)).toFixed(2)}` 
                                                    : 'N/A'}</GridItem>
                                            </Grid>
                                        </Td>
                                        <Td border="1px solid #CBD5E0" isNumeric paddingY="1px" paddingX="10px">
                                            <Switch
                                                isChecked={coupon.isActive}
                                                onChange={() => handleToggleActive(coupon.couponId, coupon.startDate, coupon.isActive)}
                                            />
                                        </Td>
                                        <Td border="1px solid #CBD5E0" paddingY="2px" paddingX="10px">
                                            <Button
                                                colorScheme="teal"
                                                onClick={() => handleEditClick(coupon.couponId, coupon.startDate)}
                                                isDisabled={coupon.isActive}
                                                style={{ opacity: coupon.isActive ? 0.5 : 1 }}
                                                size="sm"
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                colorScheme="red"
                                                onClick={() => handleDeleteCoupon(coupon.couponId, coupon.startDate)}
                                                style={{ marginLeft: "10px" }}
                                                size="sm"
                                            >
                                                Delete
                                            </Button>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
            <EditCouponModal
                isOpen={isEditModalOpen}
                onClose={() => setEditModalOpen(false)}
                couponId={selectedCoupon.couponId}
                startDate={selectedCoupon.startDate}
                onCouponUpdate={handleCouponUpdate}
                // onSave={handleCouponUpdate}
            />
        </>
    );
};

export default CouponListTable;
