import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Alert,
  AlertIcon,
  Box,
  Button,
  Input,
  TableContainer,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { getIUserDetails, triggerUIDeployment } from "../api"; // Import the required API functions
import DeployScriptModal from "./DeployScriptModal";

const Users = () => {
  const { logout } = useAuth();
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [deploying, setDeploying] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scriptVersion, setScriptVersion] = useState(null); // State to store script version
  const [currentCustomerId, setCurrentCustomerId] = useState(null); // New state for current customer ID

  const navigate = useNavigate(); // Use navigate for routing

  const openModal = () => {
    console.log("Opening modal with customer ID:", currentCustomerId); // Debugging
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const accessToken = localStorage.getItem("accessToken");
      try {
        const response = await fetch(
          "https://3f9pt6mmv2.execute-api.us-east-1.amazonaws.com/prod/v1/users",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            mode: "cors",
          }
        );

        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            await logout();
            setError(
              "Your session has expired or you do not have permission to access this resource. Please log in again."
            );
            return;
          }
          throw new Error("Failed to fetch users");
        }

        const data = await response.json();
        setUsersData(data);
      } catch (err) {
        setError(`Error: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleDeployClick = async () => {
    setDeploying(true);
    setError(null); 

    try {
      const response = await triggerUIDeployment(); // Call the deployment API
      console.log("Deployment triggered successfully:", response);
    } catch (error) {
      console.error("Deployment error:", error);
      setError(error.message || "Failed to trigger deployment.");
    } finally {
      setDeploying(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const sortedUsers = () => {
    let sortableUsers = [...usersData];

    if (sortConfig.key !== null) {
      sortableUsers.sort((a, b) => {
        let aKey = a[sortConfig.key];
        let bKey = b[sortConfig.key];

        if (typeof aKey === "string" && typeof bKey === "string") {
          aKey = aKey.toLowerCase();
          bKey = bKey.toLowerCase();
        }

        if (aKey < bKey) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aKey > bKey) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    return sortableUsers;
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const filteredUsers = sortedUsers().filter((user) => {
    return (
      user.RealUserName?.toLowerCase().includes(searchTerm) ||
      user.contactEmail?.toLowerCase().includes(searchTerm) ||
      user.companyName?.toLowerCase().includes(searchTerm)
    );
  });

  if (loading) {
    return <Spinner size="xl" />;
  }

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  const handleViewClick = (customerIdentifier) => {
    navigate(`/users/${customerIdentifier}`);
  };

  // Updated function to handle "Deploy latest Script" click
  const handleDeployScriptClick = async (customerIdentifier) => {
    try {
      const userDetails = await getIUserDetails(customerIdentifier);
      setScriptVersion(userDetails.scriptVersion); // Set the script version
      setCurrentCustomerId(customerIdentifier); // Set the current customer ID
      console.log("User Details Fetched:", userDetails); // Debugging
      openModal(); // Open the modal with the updated script version
    } catch (err) {
      console.error("Error fetching user details:", err);
      if (
        err.message.includes("Unauthorized") ||
        err.message.includes("Forbidden")
      ) {
        await logout();
        setError(
          "Your session has expired or you do not have permission to access this resource. Please log in again."
        );
      } else {
        setError(`Error: ${err.message}`);
      }
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h3
        style={{
          textAlign: "center",
          marginBottom: "10px",
          fontWeight: "bold",
        }}
      >
        Users
      </h3>

      <Input
        placeholder="Search by Username, Email, or Company"
        value={searchTerm}
        onChange={handleSearch}
        marginBottom="20px"
      />
      <TableContainer maxHeight="50vh" overflowY="auto">
        <Table size="sm" variant="unstyled">
          <Thead bg="blue.800">
            <Tr>
              <Th
                color="white"
                border="1px solid white"
                onClick={() => handleSort("RealUserName")}
                cursor="pointer"
                paddingX="10px"
              >
                Username
              </Th>
              <Th
                color="white"
                border="1px solid white"
                onClick={() => handleSort("customerIdentifier")}
                cursor="pointer"
                paddingX="10px"
              >
                Customer ID
              </Th>
              <Th
                color="white"
                border="1px solid white"
                onClick={() => handleSort("contactEmail")}
                cursor="pointer"
                paddingX="10px"
              >
                Email
              </Th>
              <Th
                color="white"
                border="1px solid white"
                onClick={() => handleSort("companyName")}
                cursor="pointer"
                paddingX="10px"
              >
                Company
              </Th>
              <Th color="white" border="1px solid white" paddingX="10px">
                Contact Person
              </Th>
              <Th color="white" border="1px solid white" paddingX="10px">
                Contact Phone
              </Th>
              <Th color="white" border="1px solid white" paddingX="10px">
                Created
              </Th>
              <Th color="white" border="1px solid white" paddingX="10px">
                AWS Account ID
              </Th>
              <Th color="white" border="1px solid white" paddingX="10px">
                Email Sent
              </Th>
              <Th color="white" border="1px solid white" paddingX="10px">
                Script version
              </Th>
              <Th color="white" border="1px solid white" paddingX="10px">
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredUsers.map((user, index) => (
              <Tr key={index}>
                <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                  {user.RealUserName || "N/A"}
                </Td>
                <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                  {user.customerIdentifier}
                </Td>
                <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                  {user.contactEmail}
                </Td>
                <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                  {user.companyName}
                </Td>
                <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                  {user.contactPerson}
                </Td>
                <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                  {user.contactPhone}
                </Td>
                <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                  {new Date(user.created).toLocaleDateString()}
                </Td>
                <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                  {user.customerAWSAccountID}
                </Td>
                <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                  {user.emailSent ? "Yes" : "No"}
                </Td>
                <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                  {user.scriptVersion}
                </Td>
                <Td
                  border="1px solid #CBD5E0"
                  paddingY="1px"
                  paddingX="10px"
                  display="flex"
                  gap="5px"
                >
                  <Button
                    colorScheme="green"
                    size="sm"
                    onClick={() => handleViewClick(user.customerIdentifier)}
                  >
                    View
                  </Button>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() =>
                      handleDeployScriptClick(user.customerIdentifier)
                    }
                    isDisabled={!user.scriptVersion}
                  >
                    Deploy latest Script
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="flex-end" mt={4} mr={4}>
        <Button
          colorScheme="blue"
          size="md"
          onClick={handleDeployClick}
          isLoading={deploying} // Show loading spinner during deployment
          disabled={deploying} // Disable button during deployment
        >
          Deploy
        </Button>
      </Box>
      {error && (
        <Alert status="error" mt={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}

      <DeployScriptModal
        isOpen={isModalOpen}
        onClose={closeModal}
        currentVersion={scriptVersion}
        customerIdentifier={currentCustomerId} // Pass the current customer ID here
      />
    </div>
  );
};

export default Users;
