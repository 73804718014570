import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { createScriptVersion } from "../api";

const AddScriptModal = ({ isOpen, onClose, onAddSuccess }) => {
  const [newScriptVersion, setNewScriptVersion] = useState("");
  const [url, setUrl] = useState(""); // New state for URL
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const handleSave = async () => {
    setIsSaving(true);
    setError(null);

    try {
      console.log("Saving new script version:", newScriptVersion);
      console.log("Saving URL:", url); // Log the URL

      // Call API to create a new script version with URL
      const createdVersion = await createScriptVersion({
        scriptVersion: newScriptVersion,
        s3Url: url, // Include the URL in the API call
      });

      // Check if the response was valid
      if (!createdVersion) {
        throw new Error("Received empty response from the server.");
      }

      console.log("Script version created successfully:", createdVersion);

      setNewScriptVersion(""); // Clear input field
      setUrl(""); // Clear URL input field
      setError(null); // Clear any previous error message
      onAddSuccess(createdVersion); // Trigger callback to update the list
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error creating script version:", error);
      setError("Error creating script version. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Script Version</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Script Version</FormLabel>
            <Input
              value={newScriptVersion}
              onChange={(e) => setNewScriptVersion(e.target.value)}
              placeholder="Enter new script version"
            />
          </FormControl>
          <FormControl mb={4}> {/* New FormControl for URL */}
            <FormLabel>URL</FormLabel>
            <Input
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter URL"
              type="url" // Specify type as url for better input validation
            />
          </FormControl>
          {error && (
            <Text color="red.500" mt={2}>
              {error}
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSave}
            isLoading={isSaving}
          >
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddScriptModal;
