import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Spinner,
  Alert,
  AlertIcon,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Switch,
  Accordion,
  Button,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
  Heading,
  Container,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { getAccessToken } from "../apiServices";
import { API_BASE_URL } from "../constants";
import { getStat } from "../api";
import { useAuth } from "../context/authContext";
import ApexChart from "./Chart";
import ApexChargesChart from "./ChargesChart";
import TimeRangeModal from "./TimeRangeModal";


const UserDetails = () => {
  const { logout } = useAuth();
  const { customerIdentifier } = useParams(); // Get the 'customerIdentifier' from the URL
  const [user, setUser] = useState(null); // To store user data
  const [loading, setLoading] = useState(true); // Loading state for fetching user details
  const [error, setError] = useState(null); // Error state
  const [isEnabled, setIsEnabled] = useState(true); // Default to `true` so toggle is on by default
  const [toggleLoading, setToggleLoading] = useState(false); // Loading state for toggle action
  const [amplifyUrl, setAmplifyUrl] = useState(null); // New state for Amplify URL
  const [privateOfferData, setPrivateOfferData] = useState(null); // New state for PrivateOffer URL
  const [bucketSizes, setBucketSizes] = useState(null); // New state for bucket sizes
  const [bucketError, setBucketError] = useState(null); // Error state for bucket sizes
  const [dimensionData, setDimensionsData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure(); // Modal control
  const [startTimeStamp, setStartTimeStamp] = useState(""); // State for start time
  const [endTimeStamp, setEndTimeStamp] = useState(""); // State for end time
  const [fetchingStats, setFetchingStats] = useState(false); // Loading state for stats fetch
  const {
    isOpen: isWarningOpen,
    onOpen: onWarningOpen,
    onClose: onWarningClose,
  } = useDisclosure();

  useEffect(() => {
    const fetchUserDetails = async () => {
      const accessToken = getAccessToken();
      try {
        const response = await fetch(
          `${API_BASE_URL}/v1/users/${customerIdentifier}`,
          {
            method: "GET", // Specify the method if necessary
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
            },
            mode: "cors",
          } // Use 'cors' mode to allow cross-origin requests
        );
        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            await logout();
            setError(
              "Your session has expired or you do not have permission to access this resource. Please log in again."
            );
            return;
          }
          throw new Error("Failed to fetch user details");
        }
        const data = await response.json();
        setUser(data); // Set the fetched user data
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false); // Stop loading once the request is completed
      }
    };

    fetchUserDetails();
  }, [customerIdentifier]);

  // Fetch bucket sizes from API
  useEffect(() => {
    if (user) {
      const fetchBucketSizes = async () => {
        const accessToken = getAccessToken();
        try {
          const username = (
            user.RealUserName || user.contactEmail
          )?.toLowerCase();
          const response = await fetch(`${API_BASE_URL}/v1/size/${username}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
            },
            mode: "cors",
          });
          if (!response.ok) {
            throw new Error("Failed to fetch bucket sizes");
          }
          const data = await response.json();
          setBucketSizes(data); // Set the fetched bucket sizes
        } catch (err) {
          setBucketError(err.message); // Set the error if the API call fails
        }
      };

      fetchBucketSizes();
    }
  }, [user]);

  // Second useEffect to fetch Amplify URL based on user data
  useEffect(() => {
    if (user) {
      const fetchAmplifyUrl = async () => {
        const accessToken = getAccessToken();
        try {
          const username = (
            user.RealUserName || user.contactEmail
          ).toLowerCase(); // Convert to lowercase
          const urlResponse = await fetch(
            `${API_BASE_URL}/v1/amplify-url/${username}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: accessToken,
              },
              mode: "cors",
            }
          );
          if (!urlResponse.ok) {
            throw new Error("Failed to fetch Amplify URL");
          }
          const urlData = await urlResponse.json();
          setAmplifyUrl(urlData.AmplifyUrl); // Set the fetched Amplify URL
        } catch (err) {
          setAmplifyUrl(null); // Set the error if the API call fails
        }
      };

      fetchAmplifyUrl();
    }
  }, [user]); // Only run when user changes

  // Third useEffect to fetch Private Offer URL based on user customerAWSAccountID
  useEffect(() => {
    if (user && user.customerAWSAccountID) {
      const fetchPrivateOfferData = async () => {
        const accessToken = getAccessToken();
        try {
          const urlResponse = await fetch(
            `${API_BASE_URL}/v1/private-offer/${user.customerAWSAccountID}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: accessToken,
              },
              mode: "cors",
            }
          );
          if (!urlResponse.ok) {
            throw new Error("Failed to fetch Private Offer Data");
          }
          const offerData = await urlResponse.json();
          setPrivateOfferData(offerData.PrivateOffers[0]); // Set the fetched Private Offer URL
        } catch (err) {
          setPrivateOfferData(null); // Set the error if the API call fails
        }
      };

      fetchPrivateOfferData();
    }
  }, [user]); // Only run when user changes

  const handleFetchDimensionData = async () => {
    if (!startTimeStamp || !endTimeStamp) {
      onWarningOpen();
      return;
    }
    try {
      setFetchingStats(true);
      const startDate = new Date(startTimeStamp);
      const endDate = new Date(endTimeStamp);

      const startTs = startDate.getTime();
      const endTs = endDate.getTime();

      const data = await getStat(customerIdentifier, user.customerAWSAccountID, startTs, endTs); // API call
      setDimensionsData(data); // Set fetched data
      onClose(); // Close the modal
    } catch (err) {
      console.error("Error fetching dimension data:", err);
      setDimensionsData(null); // Reset on error
    } finally {
      setFetchingStats(false);
    }
  };


  const toggleUserStatus = async () => {
    const accessToken = getAccessToken();
    setToggleLoading(true); // Start the loading state for the toggle
    const url = isEnabled
      ? `${API_BASE_URL}/v1/cognito/disable`
      : `${API_BASE_URL}/v1/cognito/enable`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
        mode: "cors",
        body: JSON.stringify({ username: user.contactEmail }),
      });

      if (!response.ok) {
        throw new Error(`Failed to ${isEnabled ? "disable" : "enable"} user`);
      }

      // Successfully toggled, update the state
      setIsEnabled(!isEnabled);
    } catch (err) {
      console.error(err.message);
      alert(
        `Failed to ${isEnabled ? "disable" : "enable"} user: ${err.message}`
      );
    } finally {
      setToggleLoading(false); // End the loading state for the toggle
    }
  };

  const handleToggleChange = () => {
    toggleUserStatus(); // Send API request when toggling
  };

  // If loading, show a spinner
  if (loading) {
    return <Spinner size="xl" />;
  }

  // If error occurred, show an alert
  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  // If user data is not found, show a message
  if (!user) {
    return <p>User not found</p>;
  }

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <Container
      maxW={{ base: "full", md: "container.lg", xl: "container.xl" }}
      padding={{ base: "10px", md: "20px" }}
    >
      {/* User Information Box */}
      <Box
        padding={{ base: "10px", md: "20px" }}
        bg="gray.50"
        borderRadius="md"
        boxShadow="md"
        marginBottom="20px"
        overflowX="auto" // Ensure Box handles overflow
      >
        <Heading size="sm" marginBottom="10px">
          User Details:
        </Heading>
        <Divider my={3} borderColor="#aeb6bf" />
        {/* Wrap the table in a Box to handle horizontal overflow */}
        <Box overflowX="auto">
          <Table variant="simple" size="sm" minWidth="800px">
            {" "}
            {/* Set a minimum width to prevent table from collapsing */}
            <Thead>
              <Tr>
                <Th paddingX="5px" borderColor="gray.300">
                  Username
                </Th>
                <Th paddingX="5px" borderColor="gray.300">
                  Customer Identifier
                </Th>
                <Th paddingX="5px" borderColor="gray.300">
                  Email
                </Th>
                <Th paddingX="5px" borderColor="gray.300">
                  Company
                </Th>
                <Th paddingX="5px" borderColor="gray.300">
                  Contact Person
                </Th>
                <Th paddingX="5px" borderColor="gray.300">
                  Contact Phone
                </Th>
                <Th paddingX="5px" borderColor="gray.300">
                  AWS Account ID
                </Th>
                <Th paddingX="5px" borderColor="gray.300">
                  {" "}
                  Email Sent
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td paddingX="5px" borderColor="gray.300">
                  {user.RealUserName || "N/A"}
                </Td>
                <Td paddingX="5px" borderColor="gray.300">
                  {user.customerIdentifier || "N/A"}
                </Td>
                <Td paddingX="5px" borderColor="gray.300">
                  {user.contactEmail || "N/A"}
                </Td>
                <Td paddingX="5px" borderColor="gray.300">
                  {user.companyName || "N/A"}
                </Td>
                <Td paddingX="5px" borderColor="gray.300">
                  {user.contactPerson || "N/A"}
                </Td>
                <Td paddingX="5px" borderColor="gray.300">
                  {user.contactPhone || "N/A"}
                </Td>
                <Td paddingX="5px" borderColor="gray.300">
                  {user.customerAWSAccountID || "N/A"}
                </Td>
                <Td paddingX="5px" borderColor="gray.300">
                  {user.emailSent ? "Yes" : "No"}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Box>

      {/* Accordion with toggle inside */}
      <Box
        padding={{ base: "10px", md: "20px" }}
        bg="gray.50"
        borderRadius="md"
        boxShadow="md"
        marginBottom="20px"
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="bold" size="sm">
                  Actions:
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {/* Status Toggle */}
              <Divider my={3} borderColor="#aeb6bf" />
              <Flex
                direction={{ base: "column", md: "row" }}
                justifyContent="space-between"
                alignItems={{ base: "flex-start", md: "center" }}
              >
                <Box size="sm" fontWeight="bold">
                  Cognito User's Status:
                </Box>
                <Flex align="center" mt={{ base: 2, md: 0 }}>
                  {/* Show spinner while toggle action is in progress */}
                  {toggleLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <Switch
                      size="sm"
                      isChecked={isEnabled} // The switch is on by default
                      onChange={handleToggleChange}
                      isDisabled={toggleLoading} // Disable the switch while loading
                    />
                  )}
                  <Box ml={3} size="sm" fontWeight={500}>
                    {isEnabled ? "Enabled" : "Disabled"}
                  </Box>
                </Flex>
              </Flex>

              {/* Display Amplify URL */}
              <Box mt="10px" size="sm">
                <strong>Amplify URL:</strong>
                {amplifyUrl !== null ? (
                  <a
                    style={{ marginLeft: "15px" }}
                    href={amplifyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {amplifyUrl}
                  </a>
                ) : (
                  " Not Available"
                )}
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      {/* Private Offer Details */}
      <Box
        padding={{ base: "10px", md: "20px" }}
        bg="gray.50"
        borderRadius="md"
        boxShadow="md"
        marginBottom="20px"
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="bold" size="sm">
                  Private Offer Details:
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Divider my={3} borderColor="#aeb6bf" />

              {/* Private Offer Data in Table */}
              {privateOfferData ? (
                <Box>
                  <Table variant="simple" size="sm" minWidth="600px">
                    <Thead>
                      <Tr>
                        <Th>Private Offer URL</Th>
                        <Th>Coupon Code</Th>
                        <Th>Created At</Th>
                        <Th>Expiration Date</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          <Button
                            width={{ base: "7rem", md: "9rem" }}
                            colorScheme="blue"
                            onClick={() =>
                              window.open(
                                privateOfferData.PrivateOfferUrl,
                                "_blank"
                              )
                            }
                            size="sm"
                          >
                            Open Private Offer
                          </Button>
                        </Td>
                        <Td>{privateOfferData.couponCode || "N/A"}</Td>
                        <Td>
                          {privateOfferData.timestamp
                            ? formatDate(privateOfferData.timestamp)
                            : "N/A"}
                        </Td>
                        <Td>
                          {privateOfferData.expiryDate
                            ? formatDate(privateOfferData.expiryDate)
                            : "N/A"}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              ) : (
                <Box mt="10px" size="sm">
                  <strong>Private Offer Data:</strong> Not Available
                </Box>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      {/* Storage Accordion */}
      <Box
        padding={{ base: "10px", md: "20px" }}
        bg="gray.50"
        borderRadius="md"
        boxShadow="md"
        marginBottom="20px"
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="bold" size="sm">
                  Storage:
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Divider my={3} borderColor="#aeb6bf" />
              {/* Display bucket sizes or error */}
              {bucketSizes ? (
                <Box>
                  <Table variant="simple" size="sm" minWidth="600px">
                    <Thead>
                      <Tr>
                        <Th>Bucket Name</Th>
                        <Th>Size (GB)</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {/* Iterate over bucket_sizes to dynamically render rows */}
                      {Object.entries(bucketSizes.bucket_sizes).map(
                        ([bucketName, size]) => (
                          <Tr key={bucketName}>
                            <Td>
                              {/* Construct AWS S3 console link and make it clickable */}
                              <a
                                href={`https://us-east-1.console.aws.amazon.com/s3/buckets/${bucketName}?region=us-east-1&bucketType=general&tab=objects`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                }}
                              >
                                {bucketName}
                              </a>
                            </Td>
                            <Td>{size !== undefined ? size : "N/A"}</Td>
                          </Tr>
                        )
                      )}
                      <Tr fontWeight="bold">
                        <Td>Total</Td>
                        <Td>
                          {bucketSizes.total_size_in_gb !== undefined
                            ? `${bucketSizes.total_size_in_gb} GB`
                            : "N/A"}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              ) : bucketError ? (
                <Alert status="error">
                  <AlertIcon />
                  {bucketError}
                </Alert>
              ) : (
                <Spinner size="sm" />
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
      <Box
        padding={{ base: "10px", md: "20px" }}
        bg="gray.50"
        borderRadius="md"
        boxShadow="md"
        marginBottom="20px"
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="bold" size="sm">
                  Stats:
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} position="relative">
            <Button
                size="sm"
                colorScheme="blue"
                position="absolute"
                right= "0"
                onClick={onOpen}
              >
                Select Time Range
            </Button>
              <Box pt="12">
                <ApexChart statsData={dimensionData} />
              </Box>
              <Box>
                <ApexChargesChart statsData={dimensionData} />
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      <TimeRangeModal
        isOpen={isOpen}
        onClose={onClose}
        startTimeStamp={startTimeStamp}
        setStartTimeStamp={setStartTimeStamp}
        endTimeStamp={endTimeStamp}
        setEndTimeStamp={setEndTimeStamp}
        onProceed={handleFetchDimensionData}
        isLoading={fetchingStats}
      />

      <Modal isOpen={isWarningOpen} onClose={onWarningClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Warning</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Please select both a valid start date and end date before proceeding.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={onWarningClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default UserDetails;
