import React, { useState } from "react";
import { Button, Flex } from "@chakra-ui/react";
import ScriptVersionListTable from "./VersionsListTable";
import AddScriptModal from "./AddScriptModal";

const ScriptVersions = () => {
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const handleAddSuccess = () => {
    setRefreshKey((prevKey) => prevKey + 1); 
    setAddModalOpen(false); 
  };

  return (
    <div>
      <Flex justifyContent="flex-end" w="full" mb={4}>
        <Button colorScheme="blue" onClick={() => setAddModalOpen(true)}>
          Add New Version
        </Button>
      </Flex>
      <ScriptVersionListTable key={refreshKey} />{" "}
      <AddScriptModal
        isOpen={isAddModalOpen}
        onClose={() => setAddModalOpen(false)}
        onAddSuccess={handleAddSuccess}
      />
    </div>
  );
};

export default ScriptVersions;
