import React, { useEffect, useState } from 'react';
import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import AddCoupenModal from './AddCoupenModal';
import CouponListTable from './CouponListTable';
import OriginalCosts from './OriginalCosts';
import { getCost } from '../api'; // Adjust the import path as needed

const CoupenGenerator = () => { 
    const [originalAmounts, setOriginalAmounts] = useState(); // State for storing original costs
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const fetchOriginalCosts = async () => {
            try {
                const costsData = await getCost(); // Call the API
                setOriginalAmounts(costsData); // Update state with fetched data
            } catch (error) {
                console.error("Error fetching original costs:", error.message);
                // Handle the error appropriately, maybe show an alert or log the error
            }
        };

        fetchOriginalCosts(); // Invoke the function to fetch data
    }, []); // Empty dependency array means this runs once on mount

    return (
        <>
            <Flex justifyContent="flex-end" w="full">
                <Button colorScheme='blue' onClick={onOpen}>
                    Add Coupon
                </Button>
            </Flex>
            <AddCoupenModal isOpen={isOpen} onClose={onClose} />
            <CouponListTable  costAmounts ={originalAmounts}/>
            <OriginalCosts originalAmounts={originalAmounts} /> {/* Pass the fetched data */}
        </>
    );
}

export default CoupenGenerator;
