import React, { useState, useEffect } from "react";
import { Button, Flex, Spinner } from "@chakra-ui/react";
import CreateCompaign from "./CreateCompaignModal";
import CampaignListTable from "./CompaignListTable";
import { createCampaign, getAllCampaigns } from "../api"; // Import the API functions
import { useAuth } from "../context/authContext";

const MassCampaigns = () => {
  const { logout } = useAuth();
  const [couponCode, setCouponCode] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [error, setError] = useState(null);
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleCreateCampaign = async () => {
    if (!file) {
      console.error("Please upload a file before submitting.");
      return;
    }

    const formData = new FormData();
    formData.append("couponId", couponCode);
    formData.append("campaignName", campaignName);
    formData.append("file", file);

    try {
      setLoading(true);
      const result = await createCampaign(formData);
      console.log("Campaign created successfully:", result);

      // Reset fields after successful submission
      setCouponCode("");
      setCampaignName("");
      setFile(null);

      // Close the modal after submission
      handleCloseModal();

      // Fetch updated campaign list after creating a new campaign
      await fetchCampaigns();
    } catch (err) {
      console.error("Error creating campaign:", err);
      if (
        err.message.includes("Unauthorized") ||
        err.message.includes("Forbidden")
      ) {
        await logout();
        setError(
          "Your session has expired or you do not have permission to access this resource. Please log in again."
        );
      } else {
        setError(`Error: ${err.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch all campaigns
  const fetchCampaigns = async () => {
    try {
      setLoadingCampaigns(true);
      const campaignsData = await getAllCampaigns(); // Call the API function to fetch campaigns
      console.log("campaignData log", campaignsData);

      setCampaigns(campaignsData); // Set campaigns state with fetched data
    } catch (err) {
      console.error("Error fetching campaigns:", error);
      if (
        err.message.includes("Unauthorized") ||
        err.message.includes("Forbidden")
      ) {
        await logout();
        setError(
          "Your session has expired or you do not have permission to access this resource. Please log in again."
        );
      } else {
        setError(`Error: ${err.message}`);
      }
    } finally {
      setLoadingCampaigns(false);
    }
  };

  // Fetch campaigns when the component mounts
  useEffect(() => {
    fetchCampaigns();
  }, []);

  return (
    <>
      {/* Button to open the create campaign modal */}
      <Flex justifyContent="flex-end" w="full">
        <Button colorScheme="blue" onClick={handleOpenModal}>
          Create Campaigns
        </Button>
      </Flex>

      {/* Pass state variables and handlers as props to the CreateCompaign component */}
      <CreateCompaign
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        couponCode={couponCode}
        setCouponCode={setCouponCode}
        campaignName={campaignName}
        setCampaignName={setCampaignName}
        file={file}
        setFile={setFile}
        handleCreateCampaign={handleCreateCampaign} // Pass create campaign handler
      />

      {/* Show a loading spinner while fetching campaigns */}
      {loadingCampaigns ? (
        <Flex justifyContent="center" alignItems="center" mt={6}>
          <Spinner size="xl" />
        </Flex>
      ) : (
        // Pass campaigns as props to CampaignListTable
        <CampaignListTable campaigns={campaigns} setCampaigns={setCampaigns} />
      )}
    </>
  );
};

export default MassCampaigns;
