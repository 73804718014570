import * as React from "react";
import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
  SimpleGrid,
  FormHelperText,
} from "@chakra-ui/react";
import { API_BASE_URL } from "../constants";
import { getAccessToken } from "../apiServices";
import { fetchCoupons } from "../api";
import { useAuth } from "../context/authContext";

function AddCouponModal({ isOpen, onClose }) {
    const {logout} = useAuth();
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [sellerName, setSellerName] = useState("");
  const [couponName, setCouponName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [error, setError] = useState(null)
  const [
    discountAgainstDataProcessedPercentage,
    setDiscountAgainstDataProcessedPercentage,
  ] = useState("");
  const [totalAmountProcessed, setTotalAmountProcessed] = useState("");
  const [calculatedAmountProcessed, setCalculatedAmountProcessed] =
    useState(0);
  const [
    discountAgainstDataExportedPercentage,
    setDiscountAgainstDataExportedPercentage,
  ] = useState("");
  const [totalAmountExported, setTotalAmountExported] = useState("");
  const [calculatedAmountExported, setCalculatedAmountExported] =
    useState(0);
  const [
    discountAgainstDataStoredPercentage,
    setDiscountAgainstDataStoredPercentage,
  ] = useState("");
  const [totalAmountStored, setTotalAmountStored] = useState("");
  const [calculatedAmountStored, setCalculatedAmountStored] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    if (new Date(startDate) >= new Date(expiryDate)) {
      setErrorMessage("Expiry date must be greater than start date.");
      return;
    }

    setErrorMessage("");

    const couponData = {
      couponCode,
      sellerName,
      startDate,
      expiryDate,
      couponName,
      discountAgainstDataExported: discountAgainstDataExportedPercentage,
      discountAgainstDataProcessed: discountAgainstDataExportedPercentage,
      discountAgainstDataStored: discountAgainstDataStoredPercentage,
      isActive: false,
    };
    const accessToken = getAccessToken();
    try {
      const response = await fetch(`${API_BASE_URL}/v1/coupons/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
        mode: "cors",
        body: JSON.stringify(couponData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      await fetchCoupons();
      setCouponCode("");
      setSellerName("");
      setStartDate("");
      setExpiryDate("");
      setCouponName("");
      setDiscountAgainstDataProcessedPercentage("");
      setTotalAmountProcessed("");
      setCalculatedAmountProcessed(0);
      setDiscountAgainstDataExportedPercentage("");
      setTotalAmountExported("");
      setCalculatedAmountExported(0);
      setDiscountAgainstDataStoredPercentage("");
      setTotalAmountStored("");
      setCalculatedAmountStored(0);
      onClose();
    } catch (err) {
        if (err.message.includes("Unauthorized") || err.message.includes("Forbidden")) {
            await logout();
            setError(
                "Your session has expired or you don not have permission to access this resource. please login again. "
            );
        }else {
            setError(`Error: ${err.message}`)
        }
    }
  };

  const calculateFinalAmount = (percentage, totalAmount) => {
    const discountAmount =
      totalAmount && percentage ? (totalAmount * percentage) / 100 : 0;
    const finalAmount = totalAmount - discountAmount;
    return finalAmount.toFixed(2);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="800px">
        <ModalHeader>Add Coupon</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired mb={4}>
            <FormLabel>Coupon Name</FormLabel>
            <Input
              value={couponName}
              onChange={(e) => setCouponName(e.target.value)}
              placeholder=""
              maxLength={20}
            />
            <FormHelperText>{`${couponName.length}/20 characters`}</FormHelperText>
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Coupon Code</FormLabel>
            <Input
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
              placeholder=""
              maxLength={10}
            />
            <FormHelperText>{`${couponCode.length}/10 characters`}</FormHelperText>
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Seller Name</FormLabel>
            <Input
              value={sellerName}
              onChange={(e) => setSellerName(e.target.value)}
              placeholder=""
            />
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Discount (%)</FormLabel>
            <Input
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              placeholder=""
              type="number"
            />
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Start Date</FormLabel>
            <Input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Expiry Date</FormLabel>
            <Input
              type="date"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
            />
          </FormControl>
          {errorMessage && <Text color="red.500">{errorMessage}</Text>}

          <SimpleGrid columns={3} spacing={4} mb={4}>
            <FormControl isRequired>
              <FormLabel>Processed Data Discount (%)</FormLabel>
              <Input
                value={discountAgainstDataProcessedPercentage}
                onChange={(e) => {
                  setDiscountAgainstDataProcessedPercentage(e.target.value);
                  setCalculatedAmountProcessed(
                    calculateFinalAmount(e.target.value, totalAmountProcessed)
                  );
                }}
                placeholder=""
                type="number"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Total Amount Processed</FormLabel>
              <Input
                value={totalAmountProcessed}
                onChange={(e) => {
                  setTotalAmountProcessed(e.target.value);
                  setCalculatedAmountProcessed(
                    calculateFinalAmount(
                      discountAgainstDataProcessedPercentage,
                      e.target.value
                    )
                  );
                }}
                placeholder=""
                type="number"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Total Amount After Discount</FormLabel>
              <Input value={calculatedAmountProcessed} readOnly />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={3} spacing={4} mb={4}>
            <FormControl isRequired>
              <FormLabel>Export Data Discount (%)</FormLabel>
              <Input
                value={discountAgainstDataExportedPercentage}
                onChange={(e) => {
                  setDiscountAgainstDataExportedPercentage(e.target.value);
                  setCalculatedAmountExported(
                    calculateFinalAmount(e.target.value, totalAmountExported)
                  );
                }}
                placeholder=""
                type="number"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Total Amount Exported</FormLabel>
              <Input
                value={totalAmountExported}
                onChange={(e) => {
                  setTotalAmountExported(e.target.value);
                  setCalculatedAmountExported(
                    calculateFinalAmount(
                      discountAgainstDataExportedPercentage,
                      e.target.value
                    )
                  );
                }}
                placeholder=""
                type="number"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Total Amount After Discount</FormLabel>
              <Input value={calculatedAmountExported} readOnly />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={3} spacing={4} mb={4}>
            <FormControl isRequired>
              <FormLabel>Discount on Data Stored (%)</FormLabel>
              <Input
                value={discountAgainstDataStoredPercentage}
                onChange={(e) => {
                  setDiscountAgainstDataStoredPercentage(e.target.value);
                  setCalculatedAmountStored(
                    calculateFinalAmount(e.target.value, totalAmountStored)
                  );
                }}
                placeholder=""
                type="number"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Total Amount Stored</FormLabel>
              <Input
                value={totalAmountStored}
                onChange={(e) => {
                  setTotalAmountStored(e.target.value);
                  setCalculatedAmountStored(
                    calculateFinalAmount(
                      discountAgainstDataStoredPercentage,
                      e.target.value
                    )
                  );
                }}
                placeholder=""
                type="number"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Total Amount After Discount</FormLabel>
              <Input value={calculatedAmountStored} readOnly />
            </FormControl>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddCouponModal;
