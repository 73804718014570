import React, { useEffect, useState } from "react";
import { useAuth } from "./context/authContext";

const AdminPanel = () => {
  const { logout } = useAuth();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUsers = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await fetch(
        "https://3f9pt6mmv2.execute-api.us-east-1.amazonaws.com/prod/v1/users",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          mode: "cors",
        }
      );

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          await logout(); // Handle logout for unauthorized access
          setError(
            "Your session has expired or you do not have permission to access this resource. Please log in again."
          );
          return;
        }
        throw new Error("Failed to fetch users");
      }

      // Optionally, handle the successful response if needed
      // const data = await response.json();
      // Process data here if necessary
    } catch (err) {
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return <div></div>;
};

export default AdminPanel;
