import React, { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Box,
    Input,
    Alert,
    AlertIcon,
    Button,
} from '@chakra-ui/react';
import EditCampaignModal from './EditCompaignsModal';
import { deleteCampaign, sendOfferEmail } from '../api'; 

const CampaignListTable = ({ campaigns, setCampaigns }) => { 
    const [searchTerm, setSearchTerm] = useState('');
    const [error, setError] = useState(null);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleEdit = (campaign) => {
        setSelectedCampaign(campaign);
        setEditModalOpen(true);
    };

    const handleUpdate = (updatedCampaign) => {
        setCampaigns(prevCampaigns =>
            prevCampaigns.map(campaign =>
                campaign.campaignId === updatedCampaign.campaignId ? updatedCampaign : campaign
            )
        );
    };

    const handleDelete = async (campaignId) => {
        try {
            await deleteCampaign(campaignId);
            setCampaigns(prevCampaigns => prevCampaigns.filter(campaign => campaign.campaignId !== campaignId)); 
        } catch (error) {
            setError(error.message); 
        }
    };

    const handlePublish = async (campaign) => {
        const campaignId=campaign.campaignId
        // const recipientEmail = "recipient@example.com"; 
        // const offerUrl = `http://example.com/campaign/${campaign.campaignId}`; 

        try {
            const result = await sendOfferEmail(campaignId);
            console.log(result?.detail); 

           
            const updatedCampaign = {
                ...campaign,
                isPublished: true,
                isEmailSent: true,
            };

           
            handleUpdate(updatedCampaign);
        } catch (error) {
            setError(error.message); // Set error if email sending fails
        }
    };

    const filteredCampaigns = campaigns.filter(campaign => {
        return (
            campaign.campaignName.toLowerCase().includes(searchTerm) ||
            campaign.campaignId.toLowerCase().includes(searchTerm)
        );
    });
    

    return (
        <>
            <div style={{ padding: '20px' }}>
                <h3 style={{ textAlign: 'center', marginBottom: '10px', fontWeight: 'bold', }}  >
                    Campaign List
                </h3>

                <Input
                    placeholder="Search by Campaign Name"
                    value={searchTerm}
                    onChange={handleSearch}
                    marginBottom="20px"
                />

                {error && (
                    <Box textAlign="center" p={4} color="red.500">
                        <Alert status="error">
                            <AlertIcon />
                            {error}
                        </Alert>
                    </Box>
                )}

                <TableContainer maxHeight="50vh" overflowY="auto">
                    <Table variant="unstyled" size="sm">
                        <Thead bg="blue.800">
                            <Tr paddingX="10px">
                                <Th color="white" border="1px solid white"  paddingX="10px">Campaign Name</Th>
                                <Th color="white" border="1px solid white" paddingX="10px" isNumeric paddingRight="5px"  >Number of Emails</Th>
                                <Th color="white" border="1px solid white"  paddingX="10px" >Coupon Code</Th>
                                <Th color="white" border="1px solid white" paddingX="10px" isNumeric  >Is Email Sent</Th>
                                <Th color="white" border="1px solid white" paddingX="10px" >Publish</Th>
                                <Th color="white" border="1px solid white" paddingX="10px" >Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {filteredCampaigns.length === 0 ? (
                                <Tr>
                                    <Td colSpan={6} textAlign="center" border="1px solid #CBD5E0">
                                        No campaigns available.
                                    </Td>
                                </Tr>
                            ) : (
                                filteredCampaigns.map((campaign) => (
                                    <Tr key={campaign.campaignId}>
                                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">{campaign.campaignName}</Td>
                                        <Td border="1px solid #CBD5E0" isNumeric paddingY="1px" paddingX="10px"> {campaign.noOfEmails}</Td>
                                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">{campaign.couponCode}</Td>
                                        <Td border="1px solid #CBD5E0" isNumeric paddingY="1px" paddingX="10px">{campaign.areEmailsSent ? 'Yes' : 'No'}</Td>
                                        <Td border="1px solid #CBD5E0" paddingY="1px" paddingX="10px">
                                            <Button 
                                                colorScheme="green" 
                                                onClick={() => handlePublish(campaign)} 
                                                isDisabled={campaign.isPublished} // Disable if already published
                                                variant={campaign.isPublished ? "outline" : "solid"}
                                                size="sm"
                                                
                                            >
                                                {campaign.isPublished ? 'Published' : 'Publish'}
                                            </Button>
                                        </Td>
                                        <Td border="1px solid #CBD5E0" paddingY="2px" paddingX="10px">
                                            <Button 
                                                colorScheme="blue" 
                                                onClick={() => handleEdit(campaign)}
                                                isDisabled={campaign.isPublished}
                                                mr={2}
                                                size="sm"
                                            >
                                                Edit
                                            </Button>
                                            <Button 
                                                colorScheme="red" 
                                                onClick={() => handleDelete(campaign.campaignId)} 
                                                isDisabled={campaign.isPublished}
                                                size="sm"
                                            >
                                                Delete
                                            </Button>
                                        </Td>
                                    </Tr>
                                ))
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </div>

            {/* Edit Campaign Modal */}
            {selectedCampaign && (
                <EditCampaignModal
                    isOpen={isEditModalOpen}
                    onClose={() => setEditModalOpen(false)}
                    campaign={selectedCampaign}
                    onUpdate={handleUpdate}
                />
            )}
        </>
    );
};

export default CampaignListTable;
