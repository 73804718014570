import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Box, Spinner } from '@chakra-ui/react';
import Login from './Login';
import AdminPanel from './AdminPanel';
import SidebarWithHeader from './included/SidebarwithHeader';
import MassCampaigns from './components/MassCampaigns';
import Users from './components/Users';
import UserDetails from './components/UserDetails';
import CoupenGenerator from './components/CoupenGenerator';
import ScriptVersions from './components/ScriptVersions';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);
  const [jwtToken, setJwtToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        setJwtToken(token);
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Error getting current session:', error);
        setIsAuthenticated(false);
        navigate('/login'); // Navigate to login if not authenticated
      } finally {
        setLoading(false);
      }
    };
    checkUser();
  }, [navigate]);

  const handleLoginSuccess = (token) => {
    setJwtToken(token);
    setIsAuthenticated(true);
    navigate('/'); // Navigate to the root page on successful login
  };

  const logoutUser = async () => {
    setIsAuthenticated(false);
    setJwtToken(null);
    await Auth.signOut();
    navigate('/login'); // Redirect to login page on logout
  };

  if (loading) {
    return (
      <Box p={5}>
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      {/* Render Sidebar if the user is authenticated */}
      <Routes>
      <Route
            path="/login"
            element={isAuthenticated ? <Navigate to="/" /> : <Login onLoginSuccess={handleLoginSuccess} />}
          />
      </Routes>
      {isAuthenticated && <SidebarWithHeader isAuthenticated={isAuthenticated} logoutUser={logoutUser} />}
          <Box ml={{ base: 0, md: 60 }} p="4"> {/* Adjust margin-left for content area */}
      {/* Main Routes */}
        <Routes>
          {/* Route for Login page */}
          
          {/* Main Admin Panel Route */}
          <Route
            path="/"
            element={isAuthenticated ? <AdminPanel jwtToken={jwtToken} /> : <Navigate to="/login" />}
          />
          {/* Specific Routes for each component */}
          <Route
            path="/coupengenerator"
            element={isAuthenticated ? <CoupenGenerator /> : <Navigate to="/login" />}
          />
          <Route
            path="/masscampaigns"
            element={isAuthenticated ? <MassCampaigns /> : <Navigate to="/login" />}
          />
          <Route
            path="/scriptversions"
            element={isAuthenticated ? <ScriptVersions /> : <Navigate to="/login" />}
          />
          <Route
            path="/users"
            element={isAuthenticated ? <Users /> : <Navigate to="/login" />}
          />
          <Route
            path="/users/:customerIdentifier"
            element={isAuthenticated ? <UserDetails /> : <Navigate to="/login" />}
          />
          {/* Catch-All Route for undefined paths */}
          <Route
            path="*"
            element={isAuthenticated ? <Navigate to="/" /> : <Navigate to="/login" />}
          />
        </Routes>
      </Box>
    </>
  );
};

export default App;
